// extracted by mini-css-extract-plugin
export const root = "PlasmicTodoApp-module--root--3VVjl";
export const root__global_theme_dark = "PlasmicTodoApp-module--root__global_theme_dark--BEZLe";
export const appTitle = "PlasmicTodoApp-module--appTitle--14Q20";
export const appTitle__global_theme_dark = "PlasmicTodoApp-module--appTitle__global_theme_dark--1u7XN";
export const box___5LKe = "PlasmicTodoApp-module--box___5LKe--31lAw";
export const box__global_theme_dark___5LKevzYB = "PlasmicTodoApp-module--box__global_theme_dark___5LKevzYB--13hYg";
export const appBody = "PlasmicTodoApp-module--appBody--12QD8";
export const appBody__global_theme_dark = "PlasmicTodoApp-module--appBody__global_theme_dark--3A_Fp";
export const header = "PlasmicTodoApp-module--header--1od9J";
export const header__state_empty = "PlasmicTodoApp-module--header__state_empty--TZoLQ";
export const tasksContainer = "PlasmicTodoApp-module--tasksContainer--XKskM";
export const task__hu8Em = "PlasmicTodoApp-module--task__hu8Em--2yy60";
export const task__okhzS = "PlasmicTodoApp-module--task__okhzS--11DgT";
export const task___3U4B = "PlasmicTodoApp-module--task___3U4B--26vcs";
export const task__iyL2V = "PlasmicTodoApp-module--task__iyL2V--20pD0";
export const footer = "PlasmicTodoApp-module--footer--Vdb6C";
export const fakeStack = "PlasmicTodoApp-module--fakeStack--2aJ2J";
export const box__qrrgo = "PlasmicTodoApp-module--box__qrrgo--2Ml06";
export const box__global_theme_dark__qrrgoVzYB = "PlasmicTodoApp-module--box__global_theme_dark__qrrgoVzYB--Gb3ei";
export const box__euk3 = "PlasmicTodoApp-module--box__euk3--Gy8VP";
export const box__global_theme_dark__euk3VzYB = "PlasmicTodoApp-module--box__global_theme_dark__euk3VzYB--TSvW0";