// extracted by mini-css-extract-plugin
export const footerContainer = "PlasmicFooter-module--footerContainer--1tkmq";
export const box__mpsAf = "PlasmicFooter-module--box__mpsAf--2kIQW";
export const box__state_singularLeft__mpsAfB1A85 = "PlasmicFooter-module--box__state_singularLeft__mpsAfB1A85--1NrVY";
export const slotCount = "PlasmicFooter-module--slotCount--2LaGI";
export const box__ol2Bb = "PlasmicFooter-module--box__ol2Bb--oYYsv";
export const box__uOl4P = "PlasmicFooter-module--box__uOl4P--2jCf2";
export const toggleButton__v8SGg = "PlasmicFooter-module--toggleButton__v8SGg--2QnBE";
export const toggleButton__bhaxS = "PlasmicFooter-module--toggleButton__bhaxS--14-rr";
export const toggleButton__dqOdl = "PlasmicFooter-module--toggleButton__dqOdl--3Iicj";
export const box__dnbAq = "PlasmicFooter-module--box__dnbAq--2x1AG";
export const box__state_hasCompleted__dnbAQxRhH = "PlasmicFooter-module--box__state_hasCompleted__dnbAQxRhH--qwuKx";