// extracted by mini-css-extract-plugin
export const root = "PlasmicTask-module--root--2ztsa";
export const root__global_theme_dark = "PlasmicTask-module--root__global_theme_dark--1Tgik";
export const box___6YYcU = "PlasmicTask-module--box___6YYcU--3I28O";
export const box___9KNfD = "PlasmicTask-module--box___9KNfD--3h0Jm";
export const box__state_checked_global_theme_dark___9KNfDdOlxfVzYB = "PlasmicTask-module--box__state_checked_global_theme_dark___9KNfDdOlxfVzYB--I7Eb9";
export const img = "PlasmicTask-module--img--YSW5C";
export const img__state_checked = "PlasmicTask-module--img__state_checked--14U6n";
export const box__r1FTj = "PlasmicTask-module--box__r1FTj--1kNh6";
export const box__state_editing__r1FTjuBc9Q = "PlasmicTask-module--box__state_editing__r1FTjuBc9Q--2C6o0";
export const box___0HhG1 = "PlasmicTask-module--box___0HhG1--3jsuD";
export const slotChildren = "PlasmicTask-module--slotChildren--1u0n3";
export const slotChildren__global_theme_dark = "PlasmicTask-module--slotChildren__global_theme_dark--1xhGp";
export const slotChildren__state_checked = "PlasmicTask-module--slotChildren__state_checked--10qYB";
export const __wab_slot = "PlasmicTask-module--__wab_slot--7jQ9x";
export const slotChildren__state_checked_global_theme_dark = "PlasmicTask-module--slotChildren__state_checked_global_theme_dark--1UXK6";
export const textbox = "PlasmicTask-module--textbox--3snNJ";
export const textbox__state_editing = "PlasmicTask-module--textbox__state_editing--3yHA9";
export const textbox__state_editing_global_theme_dark = "PlasmicTask-module--textbox__state_editing_global_theme_dark--3hHmR";
export const root__state_editing = "PlasmicTask-module--root__state_editing--wPqo9";
export const root__state_editing_global_theme_dark = "PlasmicTask-module--root__state_editing_global_theme_dark--1SI1B";
export const box__xi78M = "PlasmicTask-module--box__xi78M--2ifBx";
export const button = "PlasmicTask-module--button--1xL6N";